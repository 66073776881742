import i18n from "@/i18n";

const routes = {
  path: "/tournament",
  name: i18n.t("menu.tournament.tournament"),
  component: () =>
    import(
      /* webpackChunkName: "tournament" */ "@/views/tournament/TournamentMain"
    ),
  children: [
    {
      path: "tournamentschedule",
      alias: "",
      name: "TournamentSchedule",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/tournament/TournamentSchedule"
        ),
      prop: true,
      meta: {
        location: "/tournament/tournamentschedule",
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.tournament.admin_tournament"),
        m_subtitle: i18n.t("menu.tournament.admin_tournament"),
        menu_num: 2,
        sub_menu_num: 21,
        step: [
          i18n.t("menu.tournament.tournament"),
          i18n.t("menu.tournament.admin_tournament"),
        ],
      },
    },
    {
      path: "tournamentscheduleview/:id",
      name: "TournamentScheduleView",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/tournament/TournamentScheduleView"
        ),
      children: [
        {
          path: "condition",
          alias: "",
          name: "Condition",
          component: () =>
            import(
              /* webpackChunkName: "tournament" */ "@/views/tournament/TournamentCondition"
            ),
          prop: true,
          meta: {
            location: "/tournament/tournamentschedule",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("menu.tournament.admin_tournament"),
            m_subtitle: i18n.t("menu.tournament.admin_tournament"),
            menu_num: 2,
            sub_menu_num: 21,
            step: [
              i18n.t("menu.tournament.tournament"),
              i18n.t("menu.tournament.admin_tournament"),
            ],
          },
        },
        {
          path: "ranking",
          name: "Ranking",
          component: () =>
            import(
              /* webpackChunkName: "tournament" */ "@/views/tournament/TournamentRanking"
            ),
          prop: true,
          meta: {
            location: "/tournament/tournamentschedule",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("menu.tournament.admin_tournament"),
            m_subtitle: i18n.t("menu.tournament.admin_tournament"),
            menu_num: 2,
            sub_menu_num: 21,
            step: [
              i18n.t("menu.tournament.tournament"),
              i18n.t("menu.tournament.admin_tournament"),
            ],
          },
        },
      ],
    },
    {
      path: "tournamentmember/:playerno",
      name: "TournamentMember",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/tournament/TournamentMember"
        ),
      prop: true,
      meta: {
        location: "/tournament/tournamentschedule",
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.tournament.admin_tournament"),
        m_subtitle: i18n.t("menu.tournament.admin_tournament"),
        menu_num: 2,
        sub_menu_num: 21,
        step: [
          i18n.t("menu.tournament.tournament"),
          i18n.t("menu.tournament.admin_tournament"),
        ],
      },
    },
    {
      path: "shoptournamentschedule",
      alias: "",
      name: "ShopTournamentSchedule",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/tournament/ShopTournamentSchedule"
        ),
      prop: true,
      meta: {
        location: "/tournament/shoptournamentschedule",
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.tournament.shop_tournament"),
        m_subtitle: i18n.t("menu.tournament.shop_tournament"),
        menu_num: 2,
        sub_menu_num: 22,
        step: [
          i18n.t("menu.tournament.tournament"),
          i18n.t("menu.tournament.shop_tournament"),
        ],
      },
    },
    {
      path: "Shoptournamentscheduleview/:id",
      name: "ShopTournamentScheduleView",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/tournament/ShopTournamentScheduleView"
        ),
      children: [
        {
          path: "shopcondition",
          alias: "",
          name: "ShopCondition",
          component: () =>
            import(
              /* webpackChunkName: "tournament" */ "@/views/tournament/ShopTournamentCondition"
            ),
          prop: true,
          meta: {
            location: "/tournament/shoptournamentschedule",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("menu.tournament.shop_tournament"),
            m_subtitle: i18n.t("menu.tournament.shop_tournament"),
            menu_num: 2,
            sub_menu_num: 22,
            step: [
              i18n.t("menu.tournament.tournament"),
              i18n.t("menu.tournament.shop_tournament"),
            ],
          },
        },
        {
          path: "shopranking",
          name: "ShopRanking",
          component: () =>
            import(
              /* webpackChunkName: "tournament" */ "@/views/tournament/ShopTournamentRanking"
            ),
          prop: true,
          meta: {
            location: "/tournament/shoptournamentschedule",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("menu.tournament.shop_tournament"),
            m_subtitle: i18n.t("menu.tournament.shop_tournament"),
            menu_num: 2,
            sub_menu_num: 22,
            step: [
              i18n.t("menu.tournament.tournament"),
              i18n.t("menu.tournament.shop_tournament"),
            ],
          },
        },
      ],
    },
    {
      path: "shoptournamentmember/:playerno",
      name: "ShopTournamentMember",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/tournament/ShopTournamentMember"
        ),
      prop: true,
      meta: {
        location: "/tournament/shoptournamentschedule",
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.tournament.shop_tournament"),
        m_subtitle: i18n.t("menu.tournament.shop_tournament"),
        menu_num: 2,
        sub_menu_num: 22,
        step: [
          i18n.t("menu.tournament.tournament"),
          i18n.t("menu.tournament.shop_tournament"),
        ],
      },
    },
    {
      path: "jointournament",
      name: "JoinTournament",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/tournament/JoinTournament"
        ),
      prop: true,
      meta: {
        location: "/tournament/jointournament",
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.tournament.tournament_information"),
        m_subtitle: i18n.t("menu.tournament.tournament_information"),
        menu_num: 2,
        sub_menu_num: 23,
        step: [
          i18n.t("menu.tournament.tournament"),
          i18n.t("menu.tournament.tournament_information"),
        ],
      },
    },
  ],
};

export default routes;
