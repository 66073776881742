import i18n from "@/i18n";

const routes = {
  path: "/membership",
  name: "membership",
  component: () =>
    import(
      /* webpackChunkName: "membership" */ "@/views/membership/MembershipMain"
    ),
  beforeEnter(to, from, next) {
    if (sessionStorage.getItem("token")) {
      next();
    } else {
      next({ name: "Login" });
    }
  },
  children: [
    {
      path: "mysummary",
      alias: "",
      name: "MySummary",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/membership/MySummary"
        ),
      prop: true,
      meta: {
        location: "/membership/mysummary",
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("page.membership.my_summary"),
        m_subtitle: i18n.t("page.membership.my_summary"),
        menu_num: 1,
        sub_menu_num: 11,
        step: [
          i18n.t("page.membership.membership"),
          i18n.t("page.membership.my_summary"),
        ],
      },
    },
    {
      path: "myrecords",
      name: "MyRecords",
      component: () =>
        import(
          /* webpackChunkName: "membership" */ "@/views/membership/MyRecords"
        ),
      children: [
        {
          path: "myrounds",
          alias: "",
          name: "MyRounds",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyRounds"
            ),
          prop: true,
          meta: {
            location: "/membership/myrecords/myrounds",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
            ],
          },
        },
        {
          path: "myroundsview/:id",
          name: "MyRoundsView",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyRoundsView"
            ),
          prop: true,
          meta: {
            location: "/membership/myrecords/myrounds",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
            ],
          },
        },
        {
          path: "mytournaments",
          name: "MyTournaments",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyTournaments"
            ),
          prop: true,
          meta: {
            location: "/membership/myrecords/mytournaments",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
            ],
          },
        },
        {
          path: "mytournamentsview/:id",
          name: "MyTournamentsView",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyTournamentsView"
            ),
          prop: true,
          meta: {
            location: "/membership/myrecords/mytournaments",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
            ],
          },
        },
        {
          path: "mytournamentsattend/:id",
          name: "MyTournamentsAttend",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyTournamentsAttend"
            ),
          prop: true,
          meta: {
            location: "/membership/myrecords/mytournaments",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
            ],
          },
        },
        {
          path: "myshoptournamentsview/:id",
          name: "MyShopTournamentsView",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyShopTournamentsView"
            ),
          prop: true,
          meta: {
            location: "/membership/myrecords/mytournaments",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
            ],
          },
        },

        {
          path: "myscorecard/:id",
          name: "MyScorecard",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyScorecard"
            ),
          prop: true,
          meta: {
            location: "/membership/myrecords/mytournaments",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
            ],
          },
        },
        {
          path: "mytournamentsranking/:id",
          name: "MyTournamentsRanking",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyTournamentsRanking"
            ),
          prop: true,
          meta: {
            location: "/membership/myrecords/mytournaments",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
            ],
          },
        },
        {
          path: "myshoptournamentsranking/:id",
          name: "MyShopTournamentsRanking",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyShopTournamentsRanking"
            ),
          prop: true,
          meta: {
            location: "/membership/myrecords/mytournaments",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
            ],
          },
        },
        {
          path: "myevent",
          name: "MyEvent",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyEvent"
            ),
          prop: true,
          meta: {
            location: "/membership/myrecords/myevent",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
            ],
          },
        },
        {
          path: "myminigameranking/:eventno",
          name: "MyMiniGameRanking",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyMiniGameRanking"
            ),
          prop: true,
          meta: {
            location: "/membership/myrecords/myevent",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
            ],
          },
        },
        {
          path: "mywinaminigame/:eventno",
          name: "MyWinAMiniGame",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyWinAMiniGame"
            ),
          prop: true,
          meta: {
            location: "/membership/myrecords/myevent",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
            ],
          },
        },
        {
          path: "myswing",
          name: "MySwing",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MySwing"
            ),
          prop: true,
          meta: {
            location: "/membership/myrecords/myswing",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
            ],
          },
        },
        {
          path: "myswingview/:id",
          name: "MySwingView",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MySwingView"
            ),
          prop: true,
          meta: {
            location: "/membership/myrecords/myswing",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_record"),
            m_subtitle: i18n.t("page.membership.my_record"),
            menu_num: 1,
            sub_menu_num: 12,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_record"),
            ],
          },
        },
      ],
    },
    {
      path: "myaccount",
      name: "MyAccount",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/membership/MyAccount"
        ),
      children: [
        {
          path: "myprofile",
          alias: "",
          name: "MyProfile",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/MyProfile"
            ),
          prop: true,
          meta: {
            location: "/membership/myaccount/myprofile",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_account"),
            m_subtitle: i18n.t("page.membership.my_account"),
            menu_num: 1,
            sub_menu_num: 13,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_account"),
            ],
          },
        },
        {
          path: "favoritelocation",
          name: "FavoriteLocation",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/FavoriteLocation"
            ),
          prop: true,
          meta: {
            location: "/membership/myaccount/favoritelocation",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_account"),
            m_subtitle: i18n.t("page.membership.my_account"),
            menu_num: 1,
            sub_menu_num: 13,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_account"),
            ],
          },
        },
        {
          path: "favoritelocationinfo/:shopno",
          name: "FavoriteLocationInfo",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/FavoriteLocationInfo"
            ),
          prop: true,
          meta: {
            location: "/membership/myaccount/favoritelocation",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_account"),
            m_subtitle: i18n.t("page.membership.my_account"),
            menu_num: 1,
            sub_menu_num: 13,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_account"),
            ],
          },
        },
        {
          path: "confirmpassword",
          name: "ConfirmPassword",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/ConfirmPassword"
            ),
          prop: true,
          meta: {
            location: "/membership/myaccount/confirmpassword",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.confirm_password"),
            m_subtitle: i18n.t("page.membership.confirm_password"),
            menu_num: 1,
            sub_menu_num: 13,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_account"),
            ],
          },
        },
        {
          path: "cancelmember",
          name: "CancelMember",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/CancelMember"
            ),
          props: true,
          password_yn: "",
          meta: {
            location: "/membership/myaccount/confirmpassword",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_account"),
            m_subtitle: i18n.t("page.membership.my_account"),
            menu_num: 1,
            sub_menu_num: 13,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_account"),
            ],
          },
        },
      ],
    },
    {
      path: "mybrandroom",
      name: "MyBrandRoom",
      component: () =>
        import(
          /* webpackChunkName: "membership" */ "@/views/membership/MyBrandRoom"
        ),
      children: [
        {
          path: "fittinglist",
          alias: "",
          name: "FittingList",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/FittingList"
            ),
          prop: true,
          meta: {
            location: "/membership/mybrandroom/fittinglist",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_brandroom"),
            m_subtitle: i18n.t("page.membership.my_brandroom"),
            menu_num: 1,
            sub_menu_num: 14,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_brandroom"),
            ],
          },
        },
        {
          path: "fittingswinglist/:fittingno",
          alias: "",
          name: "FittingSwingList",
          component: () =>
            import(
              /* webpackChunkName: "membership" */ "@/views/membership/FittingSwingList"
            ),
          prop: true,
          meta: {
            location: "/membership/mybrandroom/fittinglist",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_brandroom"),
            m_subtitle: i18n.t("page.membership.my_brandroom"),
            menu_num: 1,
            sub_menu_num: 15,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_brandroom"),
            ],
          },
        },
        {
          path: "fittingrequestwrite/:fittingno/:fitreqno?",
          name: "FittingRequestWrite",
          component: () =>
            import(
              /* webpackChunkName: "tournament" */ "@/views/membership/FittingRequestWrite"
            ),
          prop: true,
          meta: {
            location: "/membership/mybrandroom/fittinglist",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_brandroom"),
            m_subtitle: i18n.t("page.membership.my_brandroom"),
            menu_num: 1,
            sub_menu_num: 16,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_brandroom"),
            ],
          },
        },
        {
          path: "fittinganswerview/:fitreqno",
          name: "FittingAnswerView",
          component: () =>
            import(
              /* webpackChunkName: "tournament" */ "@/views/membership/FittingAnswerView"
            ),
          prop: true,
          meta: {
            location: "/membership/mybrandroom/fittinglist",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_brandroom"),
            m_subtitle: i18n.t("page.membership.my_brandroom"),
            menu_num: 1,
            sub_menu_num: 17,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_brandroom"),
            ],
          },
        },
        {
          path: "fittingsaleview/:fitansno",
          name: "FittingSaleView",
          component: () =>
            import(
              /* webpackChunkName: "tournament" */ "@/views/membership/FittingSaleView"
            ),
          prop: true,
          meta: {
            location: "/membership/mybrandroom/fittinglist",
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("page.membership.my_brandroom"),
            m_subtitle: i18n.t("page.membership.my_brandroom"),
            menu_num: 1,
            sub_menu_num: 18,
            step: [
              i18n.t("page.membership.membership"),
              i18n.t("page.membership.my_brandroom"),
            ],
          },
        },
      ],
    },
  ],
};

export default routes;
